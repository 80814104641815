import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import * as fromModalStoreActions from '@app/modals/store/actions'
import * as fromModalStoreSelectors from '@app/modals/store/selectors'
import { from, map, Observable, Subject } from 'rxjs'
import { ApiService } from '@app/shared/services/api.service'
import { AccountContactService, AccountService } from '@app/shared'
import { OrderService } from '@app/public/services'
import { ClaimService } from '@app/claims/services'
import { ExpenseService } from '@app/expense/services'

@Component({
  selector: 'app-create-contact-modal',
  templateUrl: './create-contact-modal.component.html',
  styleUrls: ['./create-contact-modal.component.scss']
})
export class CreateContactModalComponent implements OnInit {
  @Input()
  props
  result = []
  destroyed$ = new Subject<boolean>()
  showResult = false
  newContact = {
    name: '',
    phone: '',
    email: ''
  }
  selectedContact: any = null
  canCreate = false
  contact
  hasMatch = false
  valid = {
    name: true,
    phone: true,
    email: true
  }

  constructor(
    private store: Store<fromCoreStore.State>,
    public apiService: ApiService,
    private accountService: AccountService,
    private orderService: OrderService,
    private accountContactService: AccountContactService,
    private claimService: ClaimService,
    private expenseService: ExpenseService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.newContact.name = this.props.name
    if (this.props?.name !== '') {
      // this.canCreate = true
      this.setData('name', this.props?.name)
    }
  }

  async create() {
    let contact = null
    this.canCreate = false
    let data: any = {}
    if (this.props?.options?.createType && this.props?.options?.resource == 'ORDER') {
      data['type'] = this.props.options.createType
    }

    if (this.props?.options?.createSubType) {
      data['createSubType'] = this.props.options.createSubType
    }

    if (this.selectedContact) {
      data['contact'] = this.selectedContact
      if (this.props?.options?.resource == 'ORDER') {
        this.orderService.setPatchLocal(data)
      }
      if (this.props?.options?.resource == 'CLAIM') {
        this.claimService.setPatchLocal({
          type: this.props?.options?.createType,
          contact: data?.contact
        })
      }
      if (this.props?.options?.resource == 'EXPENSE') {
        this.expenseService.setPatchLocal({
          type: this.props?.options?.createType,
          contact: data?.contact
        })
      }
      this.close()
      return
    } else {
      contact = this.newContact
    }

    let newContact: any = {
      name: contact?.name,
      isAccount: !this.props?.filter?.accountId,
      meta: { account: {} }
    }

    if (contact.phone) {
      newContact['phones'] = [{ value: contact.phone }]
    }

    if (contact.email) {
      newContact['emails'] = [{ value: contact.email }]
    }

    if (this.props?.options?.type) {
      newContact.type = this.props?.options.type
    }

    if (this.props?.filter?.accountId) {
      const contact = await this.accountContactService
        .create(this.props?.filter?.accountId, newContact)
        .pipe(map(resp => resp.data))
        .toPromise()

      this.store.dispatch(new fromCoreStore.CreateContactSuccess({ contact: contact }))
      this.store.dispatch(new fromCoreStore.AddContactToAccount({ accountId: this.props?.filter?.accountId, contact }))
      data['contact'] = contact

      if (this.props?.options?.resource == 'ORDER') {
        this.orderService.setPatchLocal(data)
      }
      if (this.props?.options?.resource == 'CLAIM') {
        this.claimService.setPatchLocal({
          type: this.props?.options?.createType,
          contact: data?.contact
        })
      }
      if (this.props?.options?.resource == 'EXPENSE') {
        this.expenseService.setPatchLocal({
          type: this.props?.options?.createType,
          contact: data?.contact
        })
      }
    } else {
      const account = await this.accountService
        .create(newContact)
        .pipe(map(resp => resp.data))
        .toPromise()

      this.store.dispatch(new fromCoreStore.CreateContactSuccess({ contact: account }))
      data['contact'] = account
      if (this.props?.options?.resource == 'ORDER') {
        this.orderService.setPatchLocal(data)
      }
      if (this.props?.options?.resource == 'CLAIM') {
        this.claimService.setPatchLocal({
          type: this.props?.options?.createType,
          contact: data?.contact
        })
      }
      if (this.props?.options?.resource == 'EXPENSE') {
        this.expenseService.setPatchLocal({
          type: this.props?.options?.createType,
          contact: data?.contact
        })
      }
    }

    this.close()
  }

  isMatch(newContact, result) {
    return result.some(contact => {
      const nameMatches = contact?.name?.toLowerCase().trim() == newContact?.name.toLowerCase();
      
      const phoneMatches = contact.phones.some(phoneObj => phoneObj?.value?.trim() == newContact?.phone);
      
      const emailMatches = contact.emails.some(emailObj => emailObj?.value?.trim() == newContact?.email);
      
      return nameMatches || phoneMatches || emailMatches;
    });
  }

  setData(type, value) {
    this.newContact[type] = value
    this.selectedContact = null
    const isEmpty = Object.values(this.newContact).every(value => value == '')
    if (isEmpty) {
      this.showResult = false
      this.result = []
      this.valid.phone = true
      this.valid.email = true
      this.valid.name = true
      return
    }

    let query = {
      ...this.props?.filter,
      limit: 15,
      ...(this.newContact.name && { name: this.newContact.name }),
      ...(this.newContact.phone && { phone: this.newContact.phone }),
      ...(this.newContact.email && { email: this.newContact.email })
    }

    this.accountService.search(query).subscribe(
      (result) => {
        if (result?.data?.length) {
          this.hasMatch = this.isMatch(this.newContact, result?.data)
          this.showResult = true
          this.valid.phone = true
          this.valid.email = true
          this.valid.name = true
          this.canCreate = true
          setTimeout(() => {
            this.result = result?.data
          }, 250)
        } else {
          this.showResult = false
          this.result = []

          if (this.newContact.phone !== '' && this.newContact.phone?.length < 10) {
            this.valid.phone = false
          } else {
            this.valid.phone = true
          }

          if (this.newContact.email !== '') {
            this.valid.email = this.isValidEmail(this.newContact.email)
          } else {
            this.valid.email = true
          }

          if (this.newContact.name !== '') {
            this.valid.name = true
          } else {
            this.valid.name = false
          }

          if (this.valid.name && this.valid.phone && this.valid.email) {
            this.valid.name = true
            this.canCreate = true
          } else {
            this.canCreate = false
          }
        }
      },
      (error) => {
        console.error('Error:', error)
      }
    )


  }

  onSelect(contact) {
    if (this.selectedContact?._id == contact?._id) {
      this.selectedContact = null
    } else {
      this.selectedContact = contact
    }
  }

  formatPhone(phone) {
    if (phone.length == 10) {
      return '(' + phone?.substring(0, 3) + ') ' + phone?.substring(3, 6) + '-' + phone?.substring(6, 10)
    }
    else {
      return phone
    }
  }

  isValidEmail(email) {
    const regex = /^\S+@\S+\.\S+$/
    return regex.test(email)
}

  close = () => this.store.dispatch(new fromModalStoreActions.CloseModal())
}
