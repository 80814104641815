import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import * as fromModalStoreActions from '@app/modals/store/actions'
import * as fromModalStoreSelectors from '@app/modals/store/selectors'
import { from, Observable, Subject } from 'rxjs'
import { genMockStop, InspectionDetails, Order, OrderVehicle, Stop, Trip } from '@app/shared/models'
import { environment } from 'environments/environment'
import { filter, take, tap } from 'rxjs/operators'
import { deepSet } from '@app/shared/utils/object-deep.util'
import { ApiService } from '@app/shared/services/api.service'

@Component({
  selector: 'app-custom-bol-modal',
  templateUrl: './custom-bol-modal.component.html',
  styleUrls: ['./custom-bol-modal.component.scss']
})
export class CustomBOLModalComponent implements OnInit {
  @Input()
  props

  orders$: Observable<Order[]>

  orders: Order[] = []
  origin: Stop = genMockStop()
  destination: Stop = genMockStop()
  vehicles:any = []
  originInspaction: InspectionDetails
  deliveryInspaction: InspectionDetails
  trip

  private vehicleCount = 0

  isDownloading = false

  custom_BOL$ = new Subject<string>()
  destroyed$ = new Subject<boolean>();
  _env = environment
  tripDownload
  tripDownloadName:string
  isLoadedTrip = true
  constructor(private store: Store<fromCoreStore.State>, public apiService: ApiService, private cdr: ChangeDetectorRef) {
    // this.orders$ = this.store.select(fromModalStoreSelectors.selectAllOrders)
    // this.stops$ = this.store.select(fromModalStoreSelectors.selectAllStops)
    // this.vehicles$ = this.store.select(fromModalStoreSelectors.selectAllVehicles)
    // this.trip$ = this.store.select(fromModalStoreSelectors.selectCurrentTrip)
    // this.drivers$ = this.store.select(fromModalStoreSelectors.selectAllContacts)
  }

  ngOnInit(): void {
    this.store.dispatch(
      new fromModalStoreActions.GetOrders({
        filters: {
          _id: this.props.ordersIds,
          getFullWithLocal: true
        }
      })
    )

    this.orders$ = this.store.select(fromModalStoreSelectors.selectAllOrders).pipe(
      filter(o => !!o?.length),
      tap(o => {
        const orders = JSON.parse(JSON.stringify(o))
        this.orders = orders
        this.origin = orders?.[0]?.origin
        this.destination = orders?.[0]?.destination
        this.vehicles = orders?.flatMap(o => o?.vehicles as any as OrderVehicle[])
        this.originInspaction = orders?.find(o => o.originInspaction)?.originInspaction || { customerNotAvailable: true }
        this.deliveryInspaction = orders?.find(o => o.deliveryInspaction)?.deliveryInspaction || { customerNotAvailable: true}
        this.trip = orders?.find(o => o.trip)?.trip
      })
    )
  }
  getOrder(){
    this.store.dispatch(
      new fromModalStoreActions.GetOrders({
        filters: {
          _id: this.props.ordersIds,
          getFullWithLocal: true
        }
      })
    )
}
  updateStop = (stop: Partial<Stop> | any, fullStopObj: Stop) => {
    Object.keys(stop).forEach(k => deepSet(fullStopObj, k, stop[k]))
    let counter = 0
    const resetIds = c => ['emails', 'phones'].forEach(k => c?.[k]?.forEach(i => i._id = counter++))
    ;[...(fullStopObj.contacts || []), fullStopObj.account].forEach(resetIds)
  }

  updateTripHandler = (val) =>{
    let newTrip = {...this.trip, tripName: val}
    this.tripDownload = newTrip
    this.tripDownloadName = val
  }

  createVehicle = (vehicle: OrderVehicle) =>
    this.vehicles.push({...vehicle, _id: String(this.vehicleCount++) })
  updateVehicle = (vehicle: OrderVehicle) =>
    (this.vehicles = this.vehicles.map(v => (v._id === vehicle._id ? vehicle : v)))
  deleteVehicle = (vehicle: OrderVehicle) => (this.vehicles = this.vehicles.filter(v => v._id !== vehicle._id))

  downloadCustomBOL = () => {
    this.isDownloading = true
    if (this.origin?.account?.emails?.length) {
      for (let email of this.origin?.account?.emails) {
        if (email?.localId) {
          delete email.localId
        }
      }
    }
    if (this.origin?.account?.phones?.length) {
      for (let phone of this.origin?.account?.phones) {
        if (phone?.localId) {
          delete phone.localId
        }
      }
    }
    if (this.destination?.account?.emails?.length) {
      for (let email of this.destination?.account?.emails) {
        if (email?.localId) {
          delete email.localId
        }
      }
    }
    if (this.destination?.account?.phones?.length) {
      for (let phone of this.destination?.account?.phones) {
        if (phone?.localId) {
          delete phone.localId
        }
      }
    }
    this.apiService
      .getFile(`/orders/custom/bol/pdf`, {
        orders: this.orders,
        origin: this.origin,
        destination: this.destination,
        vehicles: this.vehicles,
        originInspaction: this.originInspaction,
        deliveryInspaction: this.deliveryInspaction,
        trip: this.tripDownload || this.trip
      })
      .pipe(take(1))
      .subscribe(resp => {
        this.isDownloading = false
        const blob = new Blob([resp.body], {
          type: resp.headers.get('content-type')
        })
        const a = document.createElement('a'),
          _url = window.URL.createObjectURL(blob)
        a.href = _url
        a.download = `${this.tripDownloadName || this.trip?.tripName} BOL`
        document.body.appendChild(a)
        a.click()
        setTimeout(function () {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(_url)
        }, 100)
      })
  }

  close = () => this.store.dispatch(new fromModalStoreActions.CloseModal())
}
