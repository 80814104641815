<div
  *ngVar="{
    contact: contact$ | async,
    types: types$ | async,
    roles: roles$ | async,
    currentUser: currentUser$ | async,
    users: users$ | async
  } as observables"
>
  <div class="flex justify-between p-4 bg-white rounded_top">
    <h4 class="text-base font-poppins text-black">Contact Details</h4>
    <button class="btn btn-link" type="button" (click)="close(observables.contact)" aria-label="Close">
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293 1.29308C0.480528 1.10561 0.734836 1.00029 1 1.00029C1.26516 1.00029 1.51947 1.10561 1.707 1.29308L6 5.58608L10.293 1.29308C10.3852 1.19757 10.4956 1.12139 10.6176 1.06898C10.7396 1.01657 10.8708 0.988985 11.0036 0.987831C11.1364 0.986677 11.2681 1.01198 11.391 1.06226C11.5139 1.11254 11.6255 1.18679 11.7194 1.28069C11.8133 1.37458 11.8875 1.48623 11.9378 1.60913C11.9881 1.73202 12.0134 1.8637 12.0123 1.99648C12.0111 2.12926 11.9835 2.26048 11.9311 2.38249C11.8787 2.50449 11.8025 2.61483 11.707 2.70708L7.414 7.00008L11.707 11.2931C11.8892 11.4817 11.99 11.7343 11.9877 11.9965C11.9854 12.2587 11.8802 12.5095 11.6948 12.6949C11.5094 12.8803 11.2586 12.9855 10.9964 12.9878C10.7342 12.99 10.4816 12.8892 10.293 12.7071L6 8.41408L1.707 12.7071C1.5184 12.8892 1.2658 12.99 1.0036 12.9878C0.741402 12.9855 0.49059 12.8803 0.305182 12.6949C0.119773 12.5095 0.0146042 12.2587 0.0123258 11.9965C0.0100473 11.7343 0.110842 11.4817 0.293 11.2931L4.586 7.00008L0.293 2.70708C0.105529 2.51955 0.000213623 2.26525 0.000213623 2.00008C0.000213623 1.73492 0.105529 1.48061 0.293 1.29308Z" fill="#9CA3AF"/>
      </svg>
    </button>
  </div>
  <div class="bg-white p-4 pt-0 rounded_bottom">

    <app-locked-item
      [type]="FOLLOW_UP_RESOURCE.ACCOUNT_CONTACT"
      [itemName]="contact?.name"
      [lockedItem]="lockedItem$ | async"
      [askToUnlockTimer]="askToUnlockListener$ | async"
      (askToUnlock)="askToUnlock()"
      (updateLock)="updateLock()"
    ></app-locked-item>

    <div class="filters_items">
      @for (tab of tabs; track $index) {
        @if (truePermissions?.includes(tab?.permission)) {
          <div
            class="filters_item"
            [ngClass]="{ active: TAB_TYPE === tab?.key }"
            (click)="TAB_TYPE = tab.key"
          >
            <a>{{ tab?.name }}</a>
          </div>
        }
      }
    </div>
    <div [ngSwitch]="TAB_TYPE" class="mt-4 px-3">
      <app-contact-details
        *ngSwitchCase="'DETAILS'"
        [contact]="observables.contact"
        [idInfo]="'contact-det'"
        [types]="observables.types"
        [roles]="observables.roles"
        [isReadOnly]="lock"
        [canChangeLoginChallenge]="canChangeLoginChallenge"
        [fields]="{
          statuses: { action: 'crud' },
          auth: { action: 'crud' },
          type: { action: 'crud', rqd: true },
          phones: { action: 'crud' },
          emails: { action: 'crud' },
          name: { action: 'crud' },
          address: { action: 'crud' },
          contact: true,
          payCustomerFee: true
        }"
        (onUpdate)="patchContact($event)"
        [options]="{label: true, headerFlex: true, noHours: true, newStyleBtns: true}"
        (onNotify)="patchContactNotify($event)"
      ></app-contact-details>
      <app-send-notifications
        *ngSwitchCase="'NOTIFICATIONS'"
        [contact]="observables.contact"
        [page]="page"
        (setPage)="setPage($event)"
        [totalCount]="totalCount"
        [limit]="limit"
        [notifications]="notifications"
      ></app-send-notifications>
      <app-commissions
        *ngSwitchCase="'COMPENSATION'"
        [contact]="observables.contact"
        [isReadOnly]="lock || !truePermissions?.includes('contact_update')"
        (onSave)="patchContact($event)"
      ></app-commissions>
      <app-contact-administration
        *ngSwitchCase="'ADMINISTRATIVE'"
        class="p-3"
        [contact]="observables.contact"
        [isReadOnly]="lock || !truePermissions?.includes('contact_update')"
        (update)="patchContact($event)"
      ></app-contact-administration>
      <app-files
        *ngSwitchCase="'FILES'"
        class="p-3"
        [isReadOnly]="lock || !truePermissions?.includes('contact_update')"
        [resource]="FOLLOW_UP_RESOURCE.ACCOUNT_CONTACT"
        [resourceId]="observables.contact?._id"
      ></app-files>
      <div class="p-3" *ngSwitchCase="'NOTES' || 'FILES'">
        <app-notes
          [options]="{ list: true }"
          [title]="'Contact Notes'"
          [filter]="true"
          [isReadOnly]="lock || !truePermissions?.includes('contact_update')"
          [display]="{
            tabs: { notes: true },
            select: {
              assignee: true,
              visibility: true,
              date: true,
              typeTask: true,
              complete: true,
              title: 'Contact Notes'
            }
          }"
          [creator]="observables?.currentUser"
          [usersEntities]="observables?.users"
          [resource]="FOLLOW_UP_RESOURCE.ACCOUNT_CONTACT"
          [resourceId]="observables.contact?._id"
        ></app-notes>
      </div>
      <div class="history-block container" style="margin-bottom: 15px" *ngSwitchCase="'HISTORY'">
        <app-history-logs
          [account]="account"
          [askToUnlockTimer]="askToUnlockTimer"
          [currentUser]="currentUser"
          [lockState]="lckdTabsState"
          [pageType]="pageType"
          [isReadOnly]="lock"
          [resourceType]="'accounts'"
          [resourceId]="props.contactId"
        >
        </app-history-logs>
      </div>
    </div>
  </div>
</div>



