import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import * as fromModalStoreActions from '@app/modals/store/actions'
import * as fromModalStoreSelectors from '@app/modals/store/selectors'
import { Observable, Subject, of } from 'rxjs'
import { FOLLOW_UP_RESOURCE, LockedItem, User } from '@app/shared/models'
import { Contact } from '@app/shared/models/contact.model'
import { getPermissions } from '@app/users/store/selectors/auth.selectors'

import { map, tap, take, takeUntil, distinctUntilChanged, switchMap } from 'rxjs/operators'
import { PermissionRole } from '@app/core/models/permission-role.model'
import * as fromUserStore from '@app/users/store'
import * as fromSidebarStore from '@app/sidebar/store'

import * as fromModalStore from '@app/modals/store'
import { LockItemWorker, LockedItemWorkerFactory } from '@app/core/services'

enum CONTACT_TAB {
  DETAILS = 'Details',
  NOTIFICATIONS = 'Notifications',
  COMPENSATION = 'Compensation',
  ADMINISTRATIVE = 'Administrative',
  FILES = 'Files',
  NOTES = 'Notes',
  HISTORY = 'History'
}
@Component({
  selector: 'app-update-contact-modal',
  templateUrl: './update-contact-modal.component.html',
  styleUrls: ['./update-contact-modal.component.scss']
})
export class UpdateContactModalComponent implements OnInit, OnDestroy {
  @Input()
  set isReadOnly(val: boolean) {
    this._isReadOnly = val
  }
  _isReadOnly = false
  canChangeLoginChallenge = false
  @Input()
  account: Contact
  @Input()
  currentUser: User
  @Input()
  fromStore: typeof fromCoreStore | typeof fromModalStore = fromCoreStore
  pageType = ''
  currentPage: string
  askToUnlockTimer
  socketId: string
  lockedTab: LockedItem
  @Input()
  props
  page = 1
  limit = 25

  tabs = [
    { name: 'Details', key:'DETAILS', permission: 'contact_update'},
    { name: 'Notifications', key: 'NOTIFICATIONS', permission: 'contact_notifications' },
    { name: 'Compensation', key: 'COMPENSATION', permission: 'contact_comp' },
    { name: 'Administrative', key: 'ADMINISTRATIVE', permission: 'contact_admin' },
    { name: 'Files', key: 'FILES', permission: 'contact_files' },
    { name: 'Notes', key: 'NOTES', permission: 'contact_notes' },
    { name: 'History', key: 'HISTORY', permission: 'contact_history' }
  ]

  contact$: Observable<Contact>
  types$: Observable<any[]>
  roles$: Observable<PermissionRole[]>
  notifications
  currentUser$: Observable<Contact>
  users$: Observable<Contact[]>
  totalCount
  permissions = []
  destroyed$ = new Subject<boolean>()

  contact: Contact

  CONTACT_TAB = CONTACT_TAB
  FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE
  askToUnlockListener$: Observable<number>

  TAB_TYPE = 'NONE'
  accountId
  lckdTabsState = [{ pageType: 'history', lckd: false, name: '', userId: '', lckdBy: false }]
  lckdItemEntities
  lockedItem$: Observable<LockedItem>
  lockedItemWorker: LockItemWorker
  lock = false
  truePermissions

  constructor(
    private store: Store<fromCoreStore.State>,
    private cdr: ChangeDetectorRef,
    private lockedItemWorkerFactory: LockedItemWorkerFactory
    ) {}

  ngOnInit(): void {
    this.props?.contactId && this.store.dispatch(new fromModalStoreActions.GetContactsByIds({ids:[this.props.contactId]}))
    this.props?.companyId && this.store.dispatch(new fromModalStoreActions.SetAccountId({ _id: this.props?.companyId }))
    this.canChangeLoginChallenge = this.props?.canChangeLoginChallenge
    this.currentUser$ = this.store.select(fromUserStore.getUser)
    this.contact$ = this.store
      .select(fromModalStoreSelectors.getContactById(this.props?.contactId))
      .pipe(tap(c => {
        if (c) {
          this.contact = c
          this.getContactNotifications(this.contact)
        }
      }))
    this.types$ = this.store
      .select(fromCoreStore.selectCoreValContacts)
      .pipe(map(v => v?.types?.filter(t => !t?.isCompany) || []))
    this.roles$ = this.store
      .select(fromModalStoreSelectors.selectAllPermissionRoles)
      .pipe(tap(r => !r?.length && this.store.dispatch(new fromModalStoreActions.GetPermissionRoles())))
    this.store.select(fromModalStoreSelectors.selectAllNotifications).pipe(takeUntil(this.destroyed$)).subscribe(n=>this.notifications = n)
    this.store
    .select(fromModalStoreSelectors.selectTotalNotifications)
    .pipe(
      takeUntil(this.destroyed$),
      tap(total => {
        this.totalCount = total
      })
    )
    .subscribe()
    this.users$ = this.store.select(fromSidebarStore.selectAllUsers)

    this.store
    .select(getPermissions)
    .pipe(
      takeUntil(this.destroyed$),
      tap(permissions => {
        if (permissions) {
          this.truePermissions = Object.entries(permissions)
            .filter(([key, value]) => key.includes('contact_') && value === true)
            .map(([key, value]) => key)
          this.tabs = this.tabs.filter(t => this.truePermissions.includes(t.permission))
          if (this.tabs?.length) {
            this.TAB_TYPE = this.tabs[0].key
          }
        }
      })
    )
    .subscribe()

    this.lockedItem$ = of(this.props.contactId).pipe(
      distinctUntilChanged(),
      tap(
        id => {
          this.lockedItemWorker = this.lockedItemWorkerFactory.createLockItemWorker(
            id,
            FOLLOW_UP_RESOURCE.ACCOUNT_CONTACT,
            fromCoreStore,
            {
              changeWindowTitle: true,
              selectResource: this.props?.selectResource
             }
          )
          this.askToUnlockListener$ = null
        }
      ),
      switchMap(() => this.lockedItemWorker.listenAndLock())
    )

    this.store
    .select(fromCoreStore.selectLockedItemsForCurrentUser)
    .pipe(
      takeUntil(this.destroyed$),
      tap((e: any) => {
        let lockedContacts = e.map(obj => obj.id)
        if (lockedContacts.includes(this.props.contactId)) {
          this.lock = true
        } else {
          this.lock = false
        }
        this.cdr.detectChanges()
      }))
    .subscribe();
  }

  patchContactNotify = (contact: Partial<Contact>) =>
    this.store.dispatch(
      new fromModalStoreActions.PatchContact({
        contact: {
          id: contact._id,
          changes: {
            ...contact
          }
        }
      })
    )

  goToNotificationTab(contact: Contact) {
    this.TAB_TYPE = 'NOTIFICATIONS'
  }

  patchContact = (contact: Partial<Contact>) => {
    this.store.dispatch(new fromModalStoreActions.PatchContact({ contact: { id: this.contact._id, changes: contact } }))
  }

  askToUnlock = () => (this.askToUnlockListener$ = this.lockedItemWorker.askToUnlock())
  updateLock = () => this.lockedItemWorker.updateLock()

  getContactNotifications = (contact: Contact) =>{
    if (!contact?.emails?.length && !contact?.phones?.length){
      return
    }
    this.store.dispatch(
      new fromModalStoreActions.GetNotifications({
        query: { to: [...contact?.emails, ...contact?.phones].map(v => v.value).filter(i => i), page: this.page, limit: this.limit }
      })
    )
  }

  setPage (page) {
    this.page = page
    this.getContactNotifications(this.contact)
  }

  close(contact: Contact) {
    this.store.dispatch(new fromModalStore.ClearNotification())
    if(contact){
      this.store.dispatch(new fromCoreStore.UpdateContactSuccess({ contact: { id: contact?._id, changes: contact } }))
    }
    this.store.dispatch(new fromModalStoreActions.CloseModal())
  }

  rejectToUnlockAdditional = applicantToUnlock =>
    this.store
      .select(fromCoreStore.selectLockedItemsForCurrentUser)
      .pipe(
        take(1),
        tap(i => {
          let lockItem
          i.forEach(e => {
            if (e.id !== this.contact._id) {
              lockItem = e
            }
          })
          this.store.dispatch(new fromCoreStore.RejectRequestToUnlockItem({ lockItem: lockItem, applicantToUnlock }))
        })
      )
      .subscribe()
  rejectToUnlockItem = () => this.rejectToUnlockAdditional({ id: this.currentPage, socketId: this.socketId })


  ngOnDestroy() {
    // const type = FOLLOW_UP_RESOURCE.ACCOUNT_CONTACT
    // this.store.dispatch(new fromCoreStore.UnLockItemMulti({ type, ids: [this.props.contactId] }));
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }


}
