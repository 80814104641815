import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NotFoundPageComponent } from '@app/core/containers/not-found-page'
import { ReleaseNotesPageComponent } from '@app/core/containers/release-notes'
import { AuthGuard } from '@app/users/guards/auth.guard'
import { CoreValsGuard, UrlPermissionsGuard } from '@app/core/guards'
@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'login',
          loadChildren: () => import('@app/users/users.module').then(m => m.UsersModule)
        },
        {
          path: 'crm',
          loadChildren: () => import('@app/crm/crm.module').then(m => m.CRMModule),
          canActivate: [AuthGuard, CoreValsGuard]
        },
        {
          path: 'trips',
          loadChildren: () => import('@app/trips/trips.module').then(m => m.TripsModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'quotes',
          loadChildren: () => import('@app/quotes/quotes.module').then(m => m.QuotesModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'loadboard',
          loadChildren: () => import('@app/quotes/quotes.module').then(m => m.QuotesModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'calendar',
          loadChildren: () => import('@app/calendar/calendar.module').then(m => m.CalendarModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'tracking',
          loadChildren: () => import('@app/tracking/tracking.module').then(m => m.TrackingModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'tasks',
          loadChildren: () => import('@app/tasks/tasks.module').then(m => m.TasksModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'claims',
          loadChildren: () => import('@app/claims/claims.module').then(m => m.ClaimsModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'reports',
          loadChildren: () => import('@app/reports/reports.module').then(m => m.ReportsModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'admin',
          loadChildren: () => import('@app/admin/admin.module').then(m => m.AdminModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'recruiter',
          loadChildren: () => import('@app/recruiter/recruiter.module').then(m => m.RecruiterModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'v',
          loadChildren: () => import('@app/public/public.module').then(m => m.PublicModule),
          canActivate: [AuthGuard, CoreValsGuard]
        },
        // {
        //   path: 'v/soft-quote',
        //   loadChildren: () => import('@app/public/soft-quote.module').then(m => m.SoftQuotePublicModule),
        //   // canActivate: [CoreValsGuard]
        // },
        {
          path: 'shop',
          loadChildren: () => import('@app/shop/shop.module').then(m => m.ShopModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'expenses',
          loadChildren: () => import('@app/expense/expenses.module').then(m => m.ExpensesModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'dashboards',
          loadChildren: () => import('@app/dashboards/dashboards.module').then(m => m.DashboardsModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        {
          path: 'invoice',
          loadChildren: () => import('@app/invoice/invoice.module').then(m => m.InvoiceModule)
        },
        {
          path: 'feedback',
          loadChildren: () => import('@app/feedbacks/feedbacks.module').then(m => m.FeedbacksModule),
          canActivate: [AuthGuard, CoreValsGuard, UrlPermissionsGuard]
        },
        { path: 'release-notes', component: ReleaseNotesPageComponent, canActivate: [AuthGuard, CoreValsGuard] },
        { path: '', redirectTo: '/quotes', pathMatch: 'full' },
        { path: '**', component: NotFoundPageComponent }
      ],
      {
        useHash: true
      }
      // DashboardsRouting
    )
  ],
  providers: [AuthGuard, CoreValsGuard, UrlPermissionsGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
