import { Injectable } from '@angular/core'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as LayoutActions from '@app/modals/store/actions/layout.actions';
import { tap } from 'rxjs/operators'
import { MODAL_COMPONENTS } from '@app/modals/models/modal.model'

const MODALS: { [id: string]: NgbModalRef } = {}

@Injectable()
export class LayoutEffects {
  openModal$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.LayoutActionTypes.OPEN_MODAL),
    tap((action: LayoutActions.OpenModal) => {
      let modalOptions = {}
      modalOptions = {
        backdrop: 'static',
        keyboard: false,
        size: action.payload.props?.size || 'lg',
        windowClass: action.payload.props?.windowClass || '',
        centered: action.payload.props?.centered || false,
      }
      if (!MODALS[action.payload.type]) {
        MODALS[action.payload.type] = this.modalService.open(MODAL_COMPONENTS[action.payload.type], modalOptions)
      }
      if (MODALS[action.payload.type].componentInstance) {
        MODALS[action.payload.type].componentInstance.props = action.payload.props
      }
    })
  ), { dispatch: false })
  
  closeModal$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.LayoutActionTypes.CLOSE_MODAL),
    tap((action: LayoutActions.CloseModal) => {
      const allIds = Object.keys(MODALS)
      const type = action.payload || allIds[allIds.length - 1]
      MODALS[type]?.close()
      delete MODALS[type]
    })
  ), { dispatch: false })

  closeAllModals$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.LayoutActionTypes.CLOSE_ALL_MODALS),
    tap(() => Object.keys(MODALS).forEach(k => {
      MODALS[k]?.close()
      delete MODALS[k]
    }))
  ), { dispatch: false })

  constructor(private actions$: Actions, private modalService: NgbModal) {}
}
