<div id="app__container">
  <div class="app__container" *ngIf="!isPublic">

    <app-toasts></app-toasts>
    <app-company-message-container></app-company-message-container>
    <app-preloader *ngIf="showPreloader" [type]="'PROGRESS_BAR'"></app-preloader>
    <div class="sidebar__container" [ngClass]="'is-active__menu'" style="z-index: 1; height: 100%">
      <!-- ------------ Desktop ------------------------------------------------ Mobile ------------------------------- -->
      @if((windowWidth > 440 && showNavigation) || (windowWidth <= 440 && mobileShowSidebar === 'nav' && showNavigation)){
        <div class="nav-container-wr">
        <app-navigation
          (onActiveMenu)="activateMenu = $event"
          (onNavMobileClose)="mobileShowSidebar = ''"
          [options]="{ isActiveSidebar: activateMenu, mobile: windowWidth <= 440 }"
          [isSubscribed]="isSubscribed"
        ></app-navigation>
      </div>
      }
      <!-- Two burgers from mobile adaptive -->
      @if(windowWidth <= 440 && loggedIn && showPreloader){
        <div class="two-sidebars-wrapper">
          <div class="navigation__wrapper nav" (click)="mobileShowSidebar = 'nav'">
              <i class="fa-solid fa-bars"></i>
          </div>
          <div class="navigation__wrapper notify" (click)="mobileShowSidebar = 'notify'">
            <i class="fa-solid fa-list"></i>
          </div>
        </div>
      }
      <div #mainContainer class="active__overflow-menu content-container" [ngClass]="{'isShowSidebar': showNavigation}" id="content-container">
        <router-outlet></router-outlet>
      </div>
      @if((windowWidth > 440 || (windowWidth <= 440 && mobileShowSidebar === 'notify')) && (currentUser$ | async) && showSidebarRight){
      <app-sidebar
        class="sidebar__wrapper"
        [options]="{ mobile: windowWidth <= 440 }"
        [appVer]="appVersion"
        [isActive]="activateMenu"
        (onActiveMenu)="activateMenu = $event; toggleSidebar()"
        (onNavMobileClose)="mobileShowSidebar = ''; activateMenu = false"
      ></app-sidebar>
      }
    </div>
  </div>
</div>

<div id="app__containers" *ngIf="isPublic" class="is-public">
  <app-toasts></app-toasts>
  <app-preloader [type]="'PROGRESS_BAR'"></app-preloader>
  <router-outlet></router-outlet>
</div>
