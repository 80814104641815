import { NgModule } from '@angular/core';

// import { ModalRootComponent } from '@app/modals/containers/modal-root.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, effects } from '@app/modals/store';
import {
  UpdateContactModalComponent,
  CustomBOLModalComponent,
  CreateContactModalComponent,
} from '@app/modals/components'
import { CoreComponentsModule, SharedModule } from '@app/shared'
import { LayoutEffects } from '@app/modals/store/effects/layout.effects'

const COMPONENTS = [
  // ModalRootComponent,
  UpdateContactModalComponent,
  CustomBOLModalComponent,
  CreateContactModalComponent,
];

@NgModule({
    imports: [
        StoreModule.forFeature('modals', reducers),
        EffectsModule.forFeature([...effects, LayoutEffects]),
        SharedModule,
        CoreComponentsModule
    ],
    declarations: COMPONENTS,
    providers: []
})
export class ModalsModule {}
