import {
  MapComponent,
  EditContactModalComponent,
  NotificationModalComponent,
  SplitOrdersModalComponent,
  ViewLocationModalComponent,
  HistoryModalComponent,
  ContactMergeComponent,
  AccountCreateModalComponent,
  BulkUpdateComponent,
  LoadboardBulkUpdateComponent,
  OrderAccountsItemModalComponent,
  OrderAccountsPayModalComponent,
  OrderAccountsNotifyModalComponent,
  PhotoModalComponent,
  PublicUpdateStatusModalComponent,
  SessionEndModalComponent,
  EquipmentFormComponent,
  MassNotificationModalComponent,
  AdjustmentsModalComponent,
  AskToUnlockModalComponent,
  ContactRequirementModalComponent,
  AccountMassNotificationModalComponent,
  CompanyRoleModalComponent,
  FileViewerComponent
} from '@app/shared/components'
import { AccountFormModalComponent } from '@app/crm/components/account-form-modal-component'
import { LoginModalComponent } from '@app/users/containers/login-modal.component'
import { VerificationModalComponent } from '@app/users/containers/verification-modal.component'
import { NewClaimComponent } from '@app/claims/components/new-claim.component'
import { NewVersionModalComponent } from '@app/core/components/new-version-modal.component'
import { SignatureComponent } from '@app/shared/components/signature/signature.component'
import { NotifyDriverTextComponent } from '@app/shared/components/modal/notify-driver-text.component'
import { ChargeCustomerModalComponent } from '@app/shared/components/modal/charge-customer-modal.component'
import { CreateRouteModal } from '@app/trips/components/create-route-modal-component'
import { UpdateContactModalComponent, CustomBOLModalComponent, CreateContactModalComponent } from '@app/modals/components'
import { CandidateFormComponent } from '@app/recruiter/components/candidate-form.component'
import { ShopModalComponent } from '@app/shop/components/shop-modal/shop-modal.component'
import { ExpenseModalComponent } from '@app/expense/components/expense-modal/expense-modal.component'
import { ShopServiceModalComponent } from '@app/shop/components/shop-service-modal/shop-service-modal.component'
import { FilesFolderModalComponent } from '@app/shared/components/modal/files-folder-modal.component'
import { ClaimAdjustersNotes } from '@app/claims/components/claim-adjusters-notes/claim-adjusters-notes.component'
import { ClaimShopsNearby } from '@app/claims/components/claim-shops-nearby/claim-shops-nearby.component'
import { OrderStopsUpdateModalComponent } from '@app/shared/components/modal/order-stops-update/order-stops-update-modal.component'

export const MODAL_COMPONENTS = {
  NEW_ACCOUNT: AccountFormModalComponent,
  LOGIN: LoginModalComponent,
  LOGIN_VERIFICATION: VerificationModalComponent,
  NEW_CLAIM: NewClaimComponent,
  MAP: MapComponent,
  EditContact: EditContactModalComponent,
  ADJUSTERS_NOTES: ClaimAdjustersNotes,
  CLAIM_SHOPS_NEARBY: ClaimShopsNearby,
  NOTIFICATION: NotificationModalComponent,
  HISTORY: HistoryModalComponent,
  SPLIT_ORDERS: SplitOrdersModalComponent,
  VIEW_LOCATION: ViewLocationModalComponent,
  SELECT_NOTIF: ContactMergeComponent,
  ACCOUNT_CREATE: AccountCreateModalComponent,
  BULK_UPDATE: BulkUpdateComponent,
  LOADBOARD_BULK_UPDATE: LoadboardBulkUpdateComponent,
  ORDER_ACCOUNTS_ITEM: OrderAccountsItemModalComponent,
  ORDER_ACCOUNTS_PAY: OrderAccountsPayModalComponent,
  ORDER_ACCOUNTS_NOTIFY: OrderAccountsNotifyModalComponent,
  COMPANY_ROLE_MODAL: CompanyRoleModalComponent,
  PHOTO: PhotoModalComponent,
  PUBLIC_UPDATE_STATUS: PublicUpdateStatusModalComponent,
  SESSION_END: SessionEndModalComponent,
  NEW_VERSION_NOTIFICATION: NewVersionModalComponent,
  EQUIPMENT_FORM: EquipmentFormComponent,
  MASS_NOTIFICATION: MassNotificationModalComponent,
  ACCOUNT_NOTIFICATION: AccountMassNotificationModalComponent,
  SIGN_BOL: SignatureComponent,
  NOTIFY_DRIVER: NotifyDriverTextComponent,
  ADJUSTMENTS: AdjustmentsModalComponent,
  CHARGE_CUSTOMER: ChargeCustomerModalComponent,
  ASK_TO_UNLOCK: AskToUnlockModalComponent,
  CONTACT_REQUIREMENT: ContactRequirementModalComponent,
  CREATE_ROUTE: CreateRouteModal,
  UPDATE_CONTACT: UpdateContactModalComponent,
  CUSTOM_BOL: CustomBOLModalComponent,
  FILE: FileViewerComponent,
  CANDIDATE_FORM: CandidateFormComponent,
  EXPENSE: ExpenseModalComponent,
  CREATE_SHOP_ORDER: ShopModalComponent,
  SHOP_SERVICE: ShopServiceModalComponent,
  FILES_FOLDER: FilesFolderModalComponent,
  ORDER_STOPS_UPDATE: OrderStopsUpdateModalComponent,
  CREATE_CONTACT: CreateContactModalComponent,
}
export type ModalComponent = ValueOf<typeof MODAL_COMPONENTS>
export type ModalType = keyof typeof MODAL_COMPONENTS

export interface Modal {
  type: ModalType,
  props?: any,
}
