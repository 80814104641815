<div class="flex justify-between p-4 bg-white rounded-t-lg items-center">
  <h4 class="text-gray-900 text-lg font-poppins">Custom BOL</h4>
  <button class="" type="button" id="create-new-account-close-modal" (click)="close()"
    aria-label="Close">
    <span aria-hidden="true">
      <i class="fas fa-close text-gray-500 text-lg"></i>
    </span>
  </button>
</div>

<div *ngVar="orders$ | async" class="modal-body bg-white p-4 pt-0 font-poppins">
  <app-input
    [label]="'Trip'"
    [idInfo]="'trip-name'"
    [value]="trip?.tripName"
    [icon]="'fa-solid fa-search'"
    (onChange)="updateTripHandler($event)"
  ></app-input>
  <div class="flex bol-items">
    <app-order-stop
      [stopType]="'Stop'"
      [idInfo]="'origin'"
      style="height: 100%;"
      class="border border-gray-300 rounded-2xl px-2"
      [stopObject]="origin"
      [options]="{ address_edit: true, localContactData: true, isNotes: false, disableDates: true, disableCreateContact: true, disableActionsDispatch: true, subContacts: true, borderNone: true }"
      (update)="updateStop($event, origin)"
    ></app-order-stop>
    <!-- border border-gray-300 rounded-2xl borderNone: true -->
    <app-order-stop
      [stopType]="'Stop'"
      [idInfo]="'destination'"
      [stopObject]="destination"
      style="height: 100%;"
      class="border border-gray-300 rounded-2xl px-2"
      [options]="{ address_edit: true, isNotes: false, localContactData: true, disableDates: true, disableCreateContact: true, disableActionsDispatch: true, subContacts: true, borderNone: true }"
      (update)="updateStop($event, destination)"
    ></app-order-stop>
  </div>
  <div class="vehicles-list-pov">
    <app-vehicles
      [options]="{ hideAdj: true }"
      [permission]="{ vehicle: {action: 'crud' }}"
      [vehicles]="vehicles"
      [setLocType]="origin"
      (onSort)="vehicles = $event"
      (onCreate)="createVehicle($event)"
      (onUpdate)="updateVehicle($event)"
      (onRemove)="deleteVehicle($event)"
    ></app-vehicles>
  </div>
  <div *ngIf="originInspaction">
    <div class="row">
      <div class="col-6 sign-block-left sign-container">
        <h2 class="text-center signature-title">Pickup</h2>

        <div class="row">
          <div class="col-12">
            <div class="sign-block">
              <img
                *ngIf="originInspaction.customerNotAvailable"
                class="n_a w-100"
                src="https://f001.backblazeb2.com/file/Diesel-Public/template/n_a.png"
                alt="Signature"
              />
              <img
                *ngIf="!originInspaction.customerNotAvailable"
                class="w-100"
                [src]="_env.apiUrl + '/images/sign/origin/' + originInspaction.customerSignatureUploadId"
                alt="Signature"
              />
            </div>
            <h4 class="sign-caption text-center">Customer's signature</h4>
            <app-btn-toggle
              *ngIf="originInspaction.customerSignatureUploadId"
              class="d-flex justify-content-center"
              label="Not available"
              [value]="originInspaction.customerNotAvailable"
              (onToggle)="originInspaction.customerNotAvailable = !originInspaction.customerNotAvailable"
            ></app-btn-toggle>
          </div>
        </div>

        <div class="card text-center">
          <div class="card-header">
            <div class="row">
              <div class="col-12">Date</div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="sign-location">
                  <app-date
                    [startKey]="'driverSignatureDate'"
                    [parent]="originInspaction"
                    (onSave)="originInspaction.driverSignatureDate = $event.driverSignatureDate"
                  ></app-date>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="deliveryInspaction" class="col-6 sign-block-right sign-container">
        <h2 class="text-center signature-title">Delivery</h2>

        <div class="row">
          <div class="col-12">
            <div class="sign-block">
              <img
                *ngIf="deliveryInspaction.customerNotAvailable"
                class="n_a w-100"
                src="https://f001.backblazeb2.com/file/Diesel-Public/template/n_a.png"
                alt="Signature"
              />
              <img
                *ngIf="!deliveryInspaction.customerNotAvailable"
                class="w-100"
                [src]="_env.apiUrl + '/images/sign/origin/' + deliveryInspaction.customerSignatureUploadId"
                alt="Signature"
              />
            </div>
            <h4 class="sign-caption text-center">Customer's signature</h4>
            <app-btn-toggle
              *ngIf="deliveryInspaction.customerSignatureUploadId"
              class="d-flex justify-content-center"
              label="Not available"
              [value]="deliveryInspaction.customerNotAvailable"
              (onToggle)="deliveryInspaction.customerNotAvailable = !deliveryInspaction.customerNotAvailable"
            ></app-btn-toggle>
          </div>
        </div>

        <div class="card text-center">
          <div class="card-header">
            <div class="row">
              <div class="col-12">Date</div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="sign-location">
                  <app-date
                    [startKey]="'driverSignatureDate'"
                    [parent]="deliveryInspaction"
                    (onSave)="deliveryInspaction.driverSignatureDate = $event.driverSignatureDate"
                  ></app-date>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>


<div class="p-4 pt-0 bg-white rounded-lg">
  <div class="text-right">
    <button (click)="downloadCustomBOL()" id="onSubmit" class="bg-blue-700 white px-3 py-2.5 rounded-xl text-base text-white">
      Download
    </button>
  </div> 
</div>
<!-- <div *ngVar="orders$ | async" class="modal-body">

  <div *ngIf="originInspaction" class="agreements-footer">
    <div class="row">
      <div class="col-6 sign-block-left sign-container">
        <h2 class="text-center">Pickup</h2>

        <div class="row">
          <div class="col-12">
            <div class="sign-block">
              <img
                *ngIf="originInspaction.customerNotAvailable"
                class="n_a w-100"
                src="https://f001.backblazeb2.com/file/Diesel-Public/template/n_a.png"
                alt="Signature"
              />
              <img
                *ngIf="!originInspaction.customerNotAvailable"
                class="w-100"
                [src]="_env.apiUrl + '/images/sign/origin/' + originInspaction.customerSignatureUploadId"
                alt="Signature"
              />
            </div>
            <h4 class="sign-caption text-center">Customer's signature</h4>
            <app-btn-toggle
              *ngIf="originInspaction.customerSignatureUploadId"
              class="d-flex justify-content-center"
              label="Not available"
              [value]="originInspaction.customerNotAvailable"
              (onToggle)="originInspaction.customerNotAvailable = !originInspaction.customerNotAvailable"
            ></app-btn-toggle>
          </div>
        </div>

        <div class="card text-center">
          <div class="card-header">
            <div class="row">
              <div class="col-12">Date</div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="sign-location">
                  <app-date
                    [startKey]="'driverSignatureDate'"
                    [parent]="originInspaction"
                    (onSave)="originInspaction.driverSignatureDate = $event.driverSignatureDate"
                  ></app-date>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="deliveryInspaction" class="col-6 sign-block-right sign-container">
        <h2 class="text-center">Delivery</h2>

        <div class="row">
          <div class="col-12">
            <div class="sign-block">
              <img
                *ngIf="deliveryInspaction.customerNotAvailable"
                class="n_a w-100"
                src="https://f001.backblazeb2.com/file/Diesel-Public/template/n_a.png"
                alt="Signature"
              />
              <img
                *ngIf="!deliveryInspaction.customerNotAvailable"
                class="w-100"
                [src]="_env.apiUrl + '/images/sign/origin/' + deliveryInspaction.customerSignatureUploadId"
                alt="Signature"
              />
            </div>
            <h4 class="sign-caption text-center">Customer's signature</h4>
            <app-btn-toggle
              *ngIf="deliveryInspaction.customerSignatureUploadId"
              class="d-flex justify-content-center"
              label="Not available"
              [value]="deliveryInspaction.customerNotAvailable"
              (onToggle)="deliveryInspaction.customerNotAvailable = !deliveryInspaction.customerNotAvailable"
            ></app-btn-toggle>
          </div>
        </div>

        <div class="card text-center">
          <div class="card-header">
            <div class="row">
              <div class="col-12">Date</div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="sign-location">
                  <app-date
                    [startKey]="'driverSignatureDate'"
                    [parent]="deliveryInspaction"
                    (onSave)="deliveryInspaction.driverSignatureDate = $event.driverSignatureDate"
                  ></app-date>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="downloadCustomBOL()"><i class="fas" [ngClass]="isDownloading ? 'fa-spinner fa-pulse' : 'fa-file-image'"></i> Download</button>
</div> -->
