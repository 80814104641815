import { RootState, StoreUtilService } from "@app/shared"
import { ActionReducer, MetaReducer } from "@ngrx/store"
export const clean = (storeService: StoreUtilService): MetaReducer<RootState> => {
    return (reducer: ActionReducer<any>) => {
        return function(state, action) {
            const initialState = storeService.originState
            switch(action.type){
                case '@ngrx/router-store/navigated':
                    storeService.originState = {...state}
                    break
                case '[ROOT] CLEAN_STORE':
                    if(initialState){
                        storeService.updateInitialState({
                            sidebar: state.sidebar,
                            auth: state.auth,
                            core: {
                                ...initialState.core,
                                lockedItems: state?.core?.lockedItems,
                                orders: {
                                    ...initialState.core.orders,
                                    filters: state?.core?.orders?.filters
                                },
                                candidates: {
                                    ...initialState.core.candidates,
                                    filter: state?.core?.candidates?.filter
                                }
                            },
                            trips:{
                                ...initialState?.trips,
                                filters: state?.trips?.filters
                            },
                            shop:{
                                ...initialState?.shop,
                                filters: state?.shop?.filters
                            },
                        })
                        return reducer(storeService.originState, action);
                    }
                    break
                default:
                    break
            }
          return reducer(state, action);
        };
    }
}